// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../src/pages/Map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-workspace-js": () => import("./../src/pages/workspace.js" /* webpackChunkName: "component---src-pages-workspace-js" */)
}

